<template>
  <a-modal
    v-model:visible="visible"
    title="添加群体"
    @ok="handleSubmit"
    @cancel="onCancel"
    :width="720"
  >
    <a-form ref="formRef" v-bind="layout" :model="formState" :rules="rules">
      <a-form-item has-feedback label="群体名称" name="cluster_name">
        <a-input
          v-model:value="formState.cluster_name"
          type="text"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item label="性别" name="gender" >
        <a-radio-group 
        v-model:value="formState.gender">
          <a-radio value="0">
            未知
          </a-radio>
          <a-radio value="1">
            男
          </a-radio>
          <a-radio value="2">
            女
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="注册时间" name="create_time">
        <a-range-picker
          v-model:value="formState.create_time"
          show-time
        />
      </a-form-item>
      <a-form-item has-feedback label="标签" name="labels">
        <a-tree-select
          v-model:value="formState.labels"
          :tree-data="labelListData"
          tree-checkable
          allow-clear
          tree-default-expand-all
          :show-checked-strategy="SHOW_PARENT"
          placeholder="请选择标签"
        />
      </a-form-item>
      <a-form-item has-feedback label="等级" name="level">
        <a-tree-select
          v-model:value="formState.level"
          :tree-data="levelListData"
          tree-checkable
          allow-clear
          tree-default-expand-all
          :show-checked-strategy="SHOW_PARENT"
          placeholder="请选择标签"
        />
      </a-form-item>
      <!-- <a-form-item has-feedback label="状态" name="status">
        <a-switch
          v-model:checked="formState.status"
          checked-children="正常"
          un-checked-children="禁用"
        />
      </a-form-item> -->
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, toRaw } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/addons/user/api";
export default defineComponent({
  name: "create-cluster",
  props: ["labelList","levelList"],
  setup(props, context) {
    console.log(props);
    // 显示弹框
    const visible = ref(false);

    // 获取等级数据
    const levelListData = ref();

    // 获取标签数据
    const labelListData = ref();

    // 打开弹框
    const showFromModal = () => {
      levelListData.value = onFormatLevelList(props.levelList);
      labelListData.value = onFormatLabelList(props.labelList);
      visible.value = true;
    };

    const formRef = ref();

    const formState = reactive({
      cluster_name: "",
      labels: [],
      gender: -1,
      level: [],
      status: true,
      create_time:[],
      reg_time:[]
    });

    // 格式化标签数据
    function onFormatLabelList(list) {
      const data = [];
      list.forEach((item) => {
        // 新的元素
        const netItem = {
          title: item.label_name,
          key: item.label_id,
          value: item.label_id,
        };
        data.push(netItem);
      });
      console.log('label_List');
      console.log(data);
      return data;
    }

    // 格式化等级数据
    function onFormatLevelList(list) {
      const data = [];
      list.forEach((item) => {
        // 新的元素
        const netItem = {
          title: item.level_name,
          key: item.level_id,
          value: item.level_id,
        };
        data.push(netItem);
      });
      console.log('label_List');
      console.log(data);
      return data;
    }


    const rules = {
      cluster_name: [
        {
          required: true,
          min: 2,
          message: "请输入至少2个字符",
          trigger: "change",
        },
      ],
      // labels: [
      //   {
      //     required: true,
      //     min: 2,
      //     message: "请输入至少2个字符",
      //     trigger: "change",
      //   },
      // ],
    };

    const layout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };

    const handleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log("values", formState, toRaw(formState));
          console.log(formState.create_time);
          if(formState.create_time.length){
            formState.reg_time[0] = formState.create_time[0].format('YYYY-MM-DD HH:mm:ss');
            formState.reg_time[1] = formState.create_time[1].format('YYYY-MM-DD HH:mm:ss');
          }
          Api.user_cluster_create(formState).then(
            (res) => {
              message.success(res.message);
              visible.value = false;
              // 通知父组件刷新列表
              context.emit("handleSubmit");
            },
            (error) => {
              message.error(error);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
    };

    return {
      formState,
      formRef,
      rules,
      layout,
      visible,
      showFromModal,
      onCancel,
      handleSubmit,
      onFormatLabelList,
      labelListData,
      onFormatLevelList,
      levelListData,
    };
  },
});
</script>